import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "ui/Loader";

export default function Login() {
  const { loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    loginWithRedirect();
  });

  return <Loader visible />;
}
